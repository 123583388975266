.about-container * {
  font-family: "Helvetica";
}

.about-container {
  display: flex;
}

.about-container > div {
  flex: 1;
  padding: 0 15px;
  padding-left: 0;
}

.about-img {
  width: 100%;
  border-radius: 4px;
}

.last-col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 900px) {
  .about-container {
    flex-direction: column;
  }
  .last-col {
    margin-top: 20px;
  }
  .last-col > div:first-child {
    margin-bottom: 20px;
  }
  .about-container > div {
    flex: 1;
    padding: 0;
  }
}
