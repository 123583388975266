.press-container * {
  font-family: "Helvetica";
}

.press-container .row {
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
}

.press-container {
  display: flex;
}

.press-container .press-items .header p {
  font-family: "Letter Gothic Std";
}

.press-container > div {
  padding: 0;
}

.press-container .press-items {
  flex: 2;
}

.press-container .press-image {
  flex: 1;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  top: 20%;
  margin-top: 20px;
  right: 0;
  left: 33%;
  bottom: 0;
  height: 70vh;
}
.press-container .press-image .press-image-container {
  width: 50%;
  height: 50%;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

.press-container .press-image img {
  border: 1px solid rgb(231, 231, 231);
  display: block;
  width: 60%;
}

.press-container .row:hover {
  border-bottom: 1px solid #000;
  margin-bottom: 4px;
}

.press-container .row.header {
  cursor: default;
}
.press-container .row.header:hover {
  border-bottom: none;
  margin-bottom: 0;
}
.press-container .row * {
  padding: 0;
  margin: 0;
}

.press-container .date {
  flex: 1;
}

.press-container .publication {
  flex: 2;
}

.press-container .article {
  flex: 6;
}

@media (max-width: 900px) {
  .press-container {
    flex-direction: column;
  }
  .press-container .press-items {
    flex: 1;
  }

  .press-container .press-items .row {
    margin-bottom: 20px;
  }

  .press-container .press-items * {
    font-size: 13px;
  }
  .press-container .press-image {
    display: none;
  }
  .press-container .date {
    flex: 0;
    padding-right: 10px;
  }

  .press-container .publication {
    flex: 1.5;
    padding-right: 10px;
  }

  .press-container .article {
    flex: 2;
  }
}
