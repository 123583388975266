.project-text {
  font-family: "Helvetica";
  text-decoration: underline;
  text-underline-offset: 5px;
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  align-self: center;
  justify-self: center;
}

.grid-item:hover .project-text {
  display: flex;
}

.grid-container {
  column-count: 3;
  column-gap: 13px;
}

.grid-item {
  /* border-radius: 30px; */
  margin-bottom: 13px;
  height: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  -webkit-transition: background-image 0.5s ease-in-out;
  transition: background-image 0.5s ease-in-out;
}

.grid-item:hover::before {
  opacity: 0.1;
}

.grid-item.small {
  height: 30vw;
}

.selected-project-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  justify-content: space-around;
  flex-direction: column;
  display: none;
  z-index: 200;
  padding: 20px;
}
.android .selected-project-container {
  padding-top: 80px;
}

.final-project-container {
  width: 32vw;
}

.selected-project-container.show {
  display: flex;
}

.selected-project-section {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex: 1;
  height: 100vhw;
}

.selected-project-container * {
  font-family: "Helvetica";
}

/* .selected-project-container .top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
  flex: 0;
} */

.project-info {
  display: flex;
  flex-direction: row;
}

.project-info > div {
  flex: 1;
}
.project-info .project {
  flex: 1;
}

.project-info .slideshow {
  flex: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-info .close-column {
  flex: 0;
}

.selected-project-container .back-arrow {
  font-size: 30px;
  cursor: pointer;
  color: lightgray;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bolder;
  flex: 0;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

.selected-project-container .back-arrow,
.selected-project-container .forward-arrow {
  font-family: "Letter Gothic Std";
  color: black;
}

.selected-project-container .forward-arrow {
  font-size: 30px;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: bolder;
  text-align: center;
  flex: 0;
  padding-top: 100px;
  padding-bottom: 100px;
}

.selected-project-container .close-button {
  font-size: 30px;
  cursor: pointer;
  flex: 1;
  text-align: right;
  font-weight: bolder;
  align-self: flex-start;
  letter-spacing: -7.5px;
  width: 80px;
  font-family: "Letter Gothic Std";
}

.project-info * {
  font-family: "Helvetica";
  font-size: 14px;
}

.project-info .project-title {
  font-size: 16px;
  margin-bottom: 20px;
  font-family: "Letter Gothic Std";
}

.project-data {
  margin-top: 20px;
}

.project-data div {
  display: inline-block;
}

.project-data * {
  font-size: 12px;
}

.project-data-label {
  font-weight: 600;
  padding-right: 3px;
}

.project-data-value {
  padding-right: 5px;
}

.image-preview {
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
  min-width: 50vw;
}

.image-preview img {
  height: 95vh;
  max-width: 70vw;
  object-fit: contain;
}

.project-award-container {
  margin: 20px 0;
}

.project-award {
  font-family: "Letter Gothic Std";
  font-size: 12px;
}
@media (max-width: 950px) {
  .selected-project-section {
    padding: 0;
    padding-top: 15px;
  }
  .selected-project-container .back-arrow,
  .selected-project-container .forward-arrow,
  .project-title,
  .project-description,
  .project-data,
  .project-data *,
  .project-info {
    font-size: 16px;
  }
  .project-title {
    font-weight: bold;
  }
  .image-preview img {
    width: 100%;
    height: auto;
  }
  .image-preview {
    min-width: 60vw;
    min-height: 72vh;
  }
  .project-info {
    display: flex;
    flex-direction: column;
  }

  .project-info > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0;
  }
  .project-info > div:nth-child(2) {
    flex: 1;
  }
  .project-info .slideshow {
    min-height: 60vh;
  }
  .project-info .slideshow img {
    min-width: 90vw;
    object-fit: contain;
  }
  .project-info .slideshow .forward-arrow,
  .project-info .slideshow .back-arrow {
    flex: 0;
    padding-top: 10px;
    padding-bottom: 0;
  }
  .project-info > div:nth-child(3) {
    flex: 0;
  }
  .project-info .project-title {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .project-info .project-data * {
    font-size: 14px;
  }
  .project-info .project-description {
    margin-top: 10px;
  }
  .selected-project-container {
    overflow-x: scroll;
  }
  .slideshow {
    flex-direction: column;
  }
  .project-info .slideshow .image-preview {
    flex: 1;
  }
  .arrow-control {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex: 0;
    width: 100%;
  }
  .close-column .close-button {
    font-size: 25px;
    letter-spacing: -6.5px;
  }
}
@media (min-width: 600px) {
  .selected-project-container .back-arrow,
  .selected-project-container .forward-arrow {
    font-family: "Letter Gothic Std";
    color: black;
    position: absolute;
    height: 50%;
    align-items: center;
    display: flex;
  }

  .selected-project-container .back-arrow {
    font-size: 30px;
    cursor: pointer;
    font-weight: bolder;
    flex: 0;
    text-align: left;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 40%;
    right: 45%;
  }

  .selected-project-container .forward-arrow {
    font-size: 30px;
    cursor: pointer;
    font-weight: bolder;
    text-align: right;
    flex: 0;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 40%;
    left: 55%;
    justify-content: flex-end;
  }
}

@media (max-width: 600px) {
  .project-award {
    font-size: 14px;
  }
  .project-text {
    display: flex;
    background-color: rgba(255, 255, 255, 0.65);
    padding: 5px 10px;
    width: 60%;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 0 auto;
    text-decoration: none;
    border-radius: 2px;
    align-self: flex-end;
    margin-top: 138vw;
    height: 20px;
    margin-bottom: 20px;
  }
  .small .project-text {
    margin-top: 88vw;
  }
  .grid-container {
    column-count: 1;
  }
  .final-project-container {
    width: 93.5vw;
  }
  .grid-item {
    height: 150vw;
    margin-bottom: 13px;
  }
  .grid-item:hover::before {
    opacity: 1;
  }
  .grid-item.small {
    height: 100vw;
  }
}
