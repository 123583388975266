.services-container * {
  font-family: "Helvetica";
  font-size: 13px;
}

.services-container .process-header {
  font-size: 15px;
}

.services-container ul {
  list-style-type: none;
  padding: 0;
}

.services-container {
  display: flex;
  min-height: 59vh;
}

.services-container > div {
  flex: 1;
  padding: 0;
}

.services-container .form-heading {
  margin-bottom: 16px;
  font-size: 16px;
  font-family: "Letter Gothic Std";
}

.process-header {
  margin-top: 16px;
}

@media (max-width: 900px) {
  .services-container {
    flex-direction: column;
    min-height: 52vh;
  }
  .services-container > div:nth-child(2) {
    margin-top: 40px !important;
    margin-bottom: 20px !important;
  }
}
