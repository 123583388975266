.contact-container * {
  font-family: 'Helvetica';
}

.contact-container {
  display: flex;
  min-height: 60vh;
}

.contact-container > div {
  padding-left: 0;
  padding-right: 30px;
  flex: 1;
}

.form-heading {
  margin-bottom: 16px;
  font-family: "Letter Gothic Std";
}

.contact-container form input, 
.contact-container form select {
  border-width: 0;
  border-bottom: 1px solid rgb(219, 219, 219);
  margin-bottom: 20px;
  width: 100%;
  padding: 0;
}
.contact-container option,
.contact-container form *::placeholder {
  color: black;
}

.contact-container form * {
  outline: none;
  font-size: 16px;
}

.contact-container form input[type="submit"] {
  padding: 5px 10px;
  color: black;
  text-decoration: none;
  font-family: "Letter Gothic Std";
  background-color: transparent;
  border: 1px solid black;
  border-radius: 30px;
  width: auto;
  cursor: pointer;
}

.contact-container form input[type="submit"]:hover {
  background-color: black;
  color: white;
}

.contact-container form input[type="submit"].sending-email {
  background-color: black;
  color: white;
  cursor: wait;
}

@media (max-width: 900px) {
  .contact-container {
    flex-direction: column;
  }
  .contact-container > div:first-child {
    margin-top: 0;
  }
  .contact-container > div {
    margin-top: 50px;
  }

  .contact-container form input[type="submit"] {
    padding-top: 8px;
  }

  .contact-container form input,
  .contact-container form select {
    border-width: 0;
    border-bottom: 1px solid rgb(219, 219, 219);
    margin-bottom: 20px;
    width: 100%;
    padding: 0;
    color: black;
    background-color: white;
    -webkit-appearance: initial;
  } 
  .contact-container > div {
    padding: 0;
  }
}