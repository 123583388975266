.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.footer > div, .footer > a {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}