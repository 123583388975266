.menu-container {
  align-items: center;
  margin: 0 auto;
  padding: 0 8px;
  margin-bottom: 20px;
  margin-top: 14vw;
  position: relative;
  z-index: 100;
}
.pc .menu-container {
  margin-top: 13vw;
}

.menu-item a {
  color: black;
  text-decoration: none;
  font-size: 20px;
  font-family: "Letter Gothic Std";
}

.menu-item {
  padding: 0;
  margin: 5px;
  border-radius: 30px;
  display: inline-block;
  border: 1px solid black;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.3s;
}

.menu-item a {
  padding: 5px 10px;
  display: inline-block;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: 0.3s;
}

.menu-item.active {
  background-color: black;
}

.menu-item.active a {
  color: white;
}

.menu-item.projects:hover {
  background-color: #E0A295;
}

.menu-item.about:hover {
  background-color: #B08E23;
}

.menu-item.services:hover {
  background-color: #BCB46D;
}

.menu-item.contact:hover {
  background-color: #E9BA4E;
}

.menu-item.press:hover {
  background-color: #D0E4E2;
}

.menu-item a:hover, .menu-item:hover a {
  color: black;
}

@media screen and (max-width: 900px) {
  .menu-container {
    margin-top: 15vw;
  }
  .menu-item a {
    font-size: 16px;
  }
}

@media screen and (max-width: 750px) {
  .menu-container {
    margin-top: 18vw;
  }
  .menu-item a {
    padding-top: 8px;
  }
}

@media screen and (max-width: 400px) {
  .menu-container {
    margin-top: 22vw;
  }
  .menu-item.projects:hover,
  .menu-item.projects:active,
  .menu-item.projects:focus,
  .menu-item.about:hover,
  .menu-item.about:active,
  .menu-item.about:focus,
  .menu-item.services:hover,
  .menu-item.services:active,
  .menu-item.services:focus,
  .menu-item.contact:hover,
  .menu-item.contact:active,
  .menu-item.contact:focus,
  .menu-item.press:hover,
  .menu-item.press:active,
  .menu-item.press:focus {
    background-color: black;
  
  }
  .menu-item:hover a,
  .menu-item:focus a,
  .menu-item:active a {
    color: white;
  }
}