.cover-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 1s;
  cursor: pointer;
}
.cover-container .text {
  font-family: "Letter Gothic Std";
  font-size: 20px;
  margin-top: 10px;
}

.cover-container.hidden {
  opacity: 0;
  cursor: default;
}

.cover-container .logo {
  height: 250px;
}

@media (max-width: 768px) {
  .cover-container .logo {
    height: 160px;
  }
  .cover-container .text {
    font-size: 14px;
    margin-top: 10px;
  }
}