* {
  font-family: "Minimal Mono";
}

.page-container {
  margin: 0 auto;
  padding: 0 13px;
}

.main-title {
  font-size: 10.51315vw;
  margin-bottom: 0;
  padding: 0;
  vertical-align: text-bottom;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  text-align: center;
  word-spacing: -2.5vw;
  margin-top: 25px;
  transition: color 0.5s;
}

.pc .main-title {
  font-size: 10.4223vw;
  margin-top: -2vw;
}
.android {
  margin-top: 80px;
}
.android .main-title {
  margin-top: 20px;
}

.pc .main-title.transparent,
.mac .main-title.transparent,
.main-title.transparent {
  color: transparent;
  -webkit-text-stroke: 2px;
  -webkit-text-stroke-color: black;
}

@media screen and (max-width: 1710px) {
  .main-title {
    font-size: 10.5vw;
  }
  .pc .main-title {
    font-size: 10.4vw;
  }
}

@media screen and (max-width: 1700px) {
  .main-title {
    font-size: 10.5vw;
  }
  .pc .main-title {
    font-size: 10.39vw;
  }
}

@media screen and (max-width: 1600px) {
  .main-title {
    font-size: 10.5vw;
  }
  .pc .main-title {
    font-size: 10.37vw;
  }
}

@media screen and (max-width: 1500px) {
  .main-title {
    font-size: 10.5vw;
  }
  .pc .main-title {
    font-size: 10.37vw;
  }
}

@media screen and (max-width: 1350px) {
  .main-title {
    font-size: 10.5vw;
  }
  .pc .main-title {
    font-size: 10.35vw;
  }
}

@media screen and (max-width: 1255px) {
  .main-title {
    font-size: 10.5vw;
  }
  .pc .main-title {
    font-size: 10.34vw;
  }
}

@media screen and (max-width: 1210px) {
  .main-title {
    font-size: 10.45vw;
  }
  .pc .main-title {
    font-size: 10.33vw;
  }
}

@media screen and (max-width: 1150px) {
  .main-title {
    font-size: 10.45vw;
  }
  .pc .main-title {
    font-size: 10.3vw;
  }
}

@media screen and (max-width: 1048px) {
  .main-title {
    font-size: 10.45vw;
  }
  .pc .main-title {
    font-size: 10.25vw;
  }
}

@media screen and (max-width: 930px) {
  .main-title {
    font-size: 10.38vw;
  }
  .pc .main-title {
    font-size: 10.2vw;
  }
}

@media screen and (max-width: 850px) {
  .main-title {
    font-size: 10.3vw;
  }
  .pc .main-title {
    font-size: 10.15vw;
  }
}

@media screen and (max-width: 750px) {
  .main-title {
    font-size: 10.3vw;
  }
  .pc .main-title {
    font-size: 10.1vw;
  }
}

@media screen and (max-width: 660px) {
  .main-title {
    font-size: 10.2vw;
  }
  .pc .main-title {
    font-size: 10vw;
  }
}
@media screen and (max-width: 400px) {
  .main-title {
    font-size: 9.9vw;
    /* word-spacing: 0px; */
  }
}
